<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Apply For Leave" slot="title" link="/helpContent/applyForLeave" />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-3">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="form.selectedStaff"
            rules="required"
          />
          <!-- :onChange="getLeaveRecord" -->
        </div>
        <div class="col-sm-2">
          <FormSelect
            label="Leave Type"
            :items="leaveTypeList"
            item-name="LeaveName"
            item-value="ID"
            v-model="form.selectedLeaveType"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-2">
          <inputDate
            label="Start Date"
            v-model="form.startDate"
            rules="required"
            @onChange="setEndDate"
          />
        </div>
        <div class="col-sm-8">
          <FormLeaveType v-model="form.firstDayLeaveType" rules="required">
          </FormLeaveType>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-2">
          <inputDate label="End Date" v-model="form.endDate" rules="required" />
        </div>
        <div class="col-sm-8">
          <FormLeaveType v-model="form.lastDayLeaveType" rules="required">
          </FormLeaveType>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-2">
          <FormSelect
            label="Reason Category"
            :items="reasonList"
            item-name="reason"
            item-value="ID"
            v-model="form.reasonCategoryId"
            rules="required"
          />
        </div>
  
        <div class="col-sm-2">
          <InputTel
            label="Emergency Contact"
            v-model="form.emergencyContacty"
            @input="isPhoneNumberValid"
          />
          <div :class="$style.error" v-if="isValid">
              Please Enter Valid Phone Number
          </div>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-5">
          <InputTextArea
            label="Reason"
            v-model="form.reason"
            rules="required"
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
<!-- 
    <FormRow>
      <div class="col-md-12">
        <h4>Leave Matrix</h4>

        <DataTable
          :actions="true"
          :data="leaveMatrixData"
          :columns="tableConfig"
          :is-loading="isLoading"
        >
        </DataTable>
      </div>
    </FormRow> -->
    <!-- <FormRow>
      <div class="col-md-6">
        <h4>Balance</h4>

        <DataTable
          :actions="true"
          :data="leaveBlanceData"
          :columns="tableBalance"
          :is-loading="isLoading"
        >
        </DataTable>
      </div>
      <div class="col-md-6">
        <h4>Leave Planner</h4>

        <DataTable
          :actions="true"
          :data="leavePlanMatrx"
          :columns="tableLeavePlan"
          :is-loading="isLoading"
        >
        </DataTable>
      </div>
    </FormRow> -->
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
      
        <div slot="footer">
      <FormButton
        
        @click="()=>{$router.go(-1)}"
          >Ok</FormButton
        ></div>
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {
  leaveType,
  getAllStaffByHRorManager,
  leaveReason,
  // leaveBalance,
  // leaveMatrix,
  applyStaffLeave,
  validateLeave,leaveList,
  // getLeavePlanner
} from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
// import tableConfig from "./table";
// import tableBalance from "./Balance";
// import tableLeavePlan from "./leavePlan";
// import DataTable from "Components/DataTable";
import FormLeaveType from "../../../components/FormLeaveType";
import InputTel from "Components/form/InputTel";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    InputDate,
    FormWrapper,
    PageTitle,
    // DataTable,
    ValidationObserver,
    FormLeaveType,
    InputTel,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        selectedStaff: null,
        selectedLeaveType: null,
        startDate: "",
        endDate: "",
        reasonCategoryId: null,
        reason: null,
        emergencyContacty: null,
        firstDayLeaveType: "0", //default value set full day
        lastDayLeaveType: "0",
        id:null,
        leaveStatus:'',
        numberOfDays:null,
      },
      staffList: [],
      // tableConfig,
      // tableBalance,
      // leaveMatrixData: [],
      // leaveBlanceData: [],
      // tableLeavePlan,
      // leavePlanMatrx: [],
      leaveTypeList: [],
      reasonList: [],
      isValid: false
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {
  },
  created() {
    this.getAllList();
  },
  methods: {
    setEndDate(val){
      if (!this.form.endDate) {
        this.form.endDate=val;
      }
    },
    getData(){
      const filters = {};
      filters.id=this.form.id
      const data = {
        filterjson: {
          filter: [filters],
        },
      };
      leaveList(data).then(res=>{
        this.form.selectedStaff=res.data[0].staff_id;
        this.form.selectedLeaveType=res.data[0].LeaveType_id;
        this.form.startDate=res.data[0].LeaveDateStart1;
        this.form.endDate=res.data[0].LeaveDateEnd1;
        this.form.reason=res.data[0].Reason;
        this.form.reasonCategoryId=res.data[0].LeaveType_id;
        this.form.firstDayLeaveType=res.data[0].FirstDayLeaveType==null?'':res.data[0].FirstDayLeaveType.toString();
        this.form.lastDayLeaveType=res.data[0].LastDayLeaveType==null?'':res.data[0].LastDayLeaveType.toString();
        this.form.leaveStatus=res.data[0].LeaveStatus;
        this.form.emergencyContacty=res.data[0].EmergencyContact!='null'?res.data[0].EmergencyContact:'';
      })
    },
    isPhoneNumberValid(e) {
      if(/^[+0-9 ]*$/i.test(e)){
        this.isValid = false;
      } else{
        this.isValid = true;
      }
    },
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result && this.isValid === false) {
          this.infoMessage.isVisible = false;
          this.showMessage.isVisible = false;
       
          const validate = {
            staffId: this.form.selectedStaff,
            leaveTypeId: this.form.selectedLeaveType,
            leaveDateStart: this.form.startDate,
            leaveDateEnd: this.form.endDate,
            firstDayLeaveType: parseInt(this.form.firstDayLeaveType),
            lastDayLeaveType: parseInt(this.form.lastDayLeaveType),
            staffLeaveId:this.form.id,
          };
          validateLeave(validate).then((res) => {
            if (res.data[1][0].status > 1) {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[1][0].message;
            } else {
              this.form.numberOfDays=res.data[0][0].noofdays;
              const data = {
            staffId: this.form.selectedStaff,
            LeaveTypeId: this.form.selectedLeaveType,
            LeaveDateStart: this.form.startDate,
            LeaveDateEnd: this.form.endDate,
            reason: this.form.reason,
            leaveReasonId: this.form.reasonCategoryId,
            firstDayLeaveType: parseInt(this.form.firstDayLeaveType),
            lastDayLeaveType: parseInt(this.form.lastDayLeaveType),
            emergencyContact: this.form.emergencyContacty?this.form.emergencyContacty:'',
            id:this.form.id,
            numberOfDays:this.form.numberOfDays
          };
              applyStaffLeave(data).then((res) => {
                this.form = {};
                this.showMessage.isVisible = true;
                this.showMessage.message = res.data[0].message;
              });
            }
          });
        }
      });
    },
    // getLeaveRecord() {
    //   if (this.form.selectedStaff) {
    //     let data = {
    //       staffId: this.form.selectedStaff,
    //     };
    //     leaveMatrix(data).then((res) => {
    //       this.leaveMatrixData = res.data;
    //     });
    //     setTimeout(() => {
    //       leaveBalance(data).then((res) => {
    //         this.leaveBlanceData = res.data;
    //       });
    //       getLeavePlanner(data).then((res) => {
    //       this.leavePlanMatrx = res.data;
    //     });
    //     }, 1000);
    //   }
    // },
    async getAllList() {
      await getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
        this.form.selectedStaff = this.userData.user_id;
        // this.getLeaveRecord();
      });
      await  leaveType().then((res) => {
        this.leaveTypeList = res.data;
      });
      await leaveReason().then((res) => {
        this.reasonList = res.data;
      });
      if (this.$route.params.id) {
        this.form.id=atob(this.$route.params.id);
        this.getData();
      }
    },
  },
};
</script>
<style lang="scss" module>
  .error {
      color: #ff3547;
      margin-top: 2px;
      max-width: 100%;
      font-size: 11px;
      line-height: 16px;
      font-weight: 400;
  }
  textarea{
        height: 180px !important;
    }
</style>